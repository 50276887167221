<template>
	<div class="app-container">
		<div class="filter-container">
			<!-- 关键字搜索 -->
            <div class="filter-item">
                <label class="label">奖品名称: </label>
                <el-input v-model="searchData.prizeName" clearable style='width:200px' placeholder="请输入奖品名称"></el-input>
                <label class="label" style="margin-left: 20px;">奖品类型:</label>
                <el-select
                    v-model="searchData.prizeType"
                    placeholder="请选择"
                    filterable
                    clearable
                    style="width: 160px;margin-right: 10px;"
                >
                    <el-option
                        v-for="(item,index) in PRIZETYPE"
                        :key="index"
                        :label="item.str"
                        :value="item.state"
                    ></el-option>
                </el-select>
                <!-- 时间筛选 -->
                <div class="filter-item">
                    <label class="label" style="margin-left: 10px;">抽奖时间:</label>
                    <el-date-picker
                        v-model="applyTime"
                        type="datetimerange"
                        range-separator="～"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    ></el-date-picker>
                </div>
                <div class="filter-item">
                    <label class="label">奖品编码: </label>
                    <el-input v-model="searchData.prizeCode" clearable style='width:200px' placeholder="请输入奖品编码"></el-input>
                </div>
            </div>
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">手机号: </label>
				<el-input v-model="searchData.phoneNumber" clearable style='width:200px' placeholder="请输入手机号"></el-input>
			</div>
            <div class="filter-item">
				<label class="label">昵称: </label>
				<el-input v-model="searchData.nickName" clearable style='width:200px' placeholder="请输入昵称"></el-input>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="width:100px;margin-right:20px;" @click="search()">搜索</el-button>
				<el-button type="infor" style="width:100px;margin-right:20px;" @click="clearSearch()">清空</el-button>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" border>
				<el-table-column prop="prizeCode" label="奖品编码"></el-table-column>
				<el-table-column label="手机号" >
					<template slot-scope="scope">
						<div>{{scope.row.phoneNumber}}</div>
					</template>
				</el-table-column>
                <el-table-column prop="nickName" label="用户昵称"></el-table-column>
				<el-table-column label="奖品名称" >
					<template slot-scope="scope">
						<div>{{scope.row.prizeName}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="prizeType" label="奖品类型">
                    <template slot-scope="scope">
						<div>{{PRIZETYPE[scope.row.prizeType].str}}</div>
					</template>
                </el-table-column>
				<el-table-column prop="luckDrawDate" label="抽奖时间"></el-table-column>
			</el-table>

			<el-pagination
				v-if="searchData.total"
				style="margin-top:20px;float:right;"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="searchData.pageNo"
				:page-sizes="[10, 20, 30, 40, 50]"
				:page-size="searchData.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="searchData.total"
			></el-pagination>
		</div>
	</div>
</template>

<script>
import {
		lockDrawData,
	} from '@/api/goods.js'
import buttonPermissions from '@/components/buttonPermissions';
export default {
	components: {
		buttonPermissions
	},
	data () {
		return {
			applyTime: '',  //时间
			searchData: {
				prizeName: '',//奖品名称
				prizeCode:'',//奖品编码
				phoneNumber:'',//手机号
				beginDate: '',    //申请开始时间
                endDate: '',      //申请结束时间
                nickName:'',    //昵称
				total: 0,
				pageNo: 1,
				pageSize: 20,
				prizeType: null,//奖品类型：0-未中奖 1-商品 2-优惠券 3-积分
			},
            loading: false,
			tableData: [],
		}
	},
	async beforeMount () {
		this.getList();
	},
	computed: {

	},
	methods: {
		// 获取列表数据
		async getList () {
			this.loading = true
			try {
				if(this.applyTime){
					this.searchData.beginDate = this.applyTime[0];
					this.searchData.endDate = this.applyTime[1];
				}else{
					this.searchData.beginDate = '';
					this.searchData.endDate = '';
				}
				let data = {
                    ...this.searchData,
					id:this.$route.query.id||0,
					
				};
				let result = '';
				result = await lockDrawData(data);
				if(result.data){
					this.tableData = result.data.records;
					this.searchData.total = result.data.total;
				}
			} catch (error) {
				console.log(error);
				this.loading = false
			} finally {
				this.loading = false;
			}
		},
		//搜索
		search () {
			this.searchData.pageNo = 1
			this.searchData.isDownload = false;    //下载文件标记
			if (!this.searchData.total) {
				this.searchData.pageSize = 20
			}
			this.getList()
		},
		// 切换显示条数
		handleSizeChange (val) {
			this.searchData.pageSize = val;
			this.getList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.searchData.pageNo = val;
			this.getList();
		},

		clearSearch(){
			this.searchData = {
				prizeName: '',//奖品名称
				prizeCode:'',//奖品编码
				phoneNumber:'',//手机号
				beginDate: '',    //申请开始时间
                endDate: '',      //申请结束时间
                nickName:'',    //昵称
				total: 0,
				pageNo: 1,
				pageSize: 20,
				prizeType: null,//奖品类型：0-未中奖 1-商品 2-优惠券 3-积分
			}
			this.applyTime = '';
			this.search();
		}
	}
}
</script>

<style lang="less" scoped>
.app-container {
	background-color: #fff;
}

.input-item {
	margin-left: 5px;
}

.table-button {
	color: #409eff;
	display: inline-block;
	margin-right: 20px;
	cursor: pointer;
}

/deep/.el-table__fixed-body-wrapper {
	background: #ffffff;
}
/deep/.el-table__fixed-header-wrapper {
	background: #f8f8f9;
}
</style>
